export const workoutArray = [
    {
        id: 1,
        workoutType: 'RestOnInterval',
        workoutName: '40/20 HIIT',
        totalRounds: 4,
        intervalMinutes: 0,
        intervalSeconds: 40,
        restMinutes: 0,
        restSeconds: 20,
        workoutDescription: 'Grab your timer! 40 seconds of work, 20 seconds of rest. Go straight down the list. Repeat the circuit 4 times. No rest between circuits, just the 20 seconds between exercises.',
        workout: [
            'Mountain Climber Burpees',
            'Pushup Sitouts',
            'Situp and Twist',
            'Pushup Plank Shoulder Touches',
            'Step Back Lunge to Single Leg Jump',
            'Single Leg Squats',
        ]
    },
    {
        id: 2,
        workoutType: 'RunningTimer',
        workoutName: '4x10',
        totalRounds: 1,
        intervalMinutes: 0,
        intervalSeconds: 0,
        restMinutes: 0,
        restSeconds: 0,
        workoutDescription: `10 Reps per exercise, 4 Rounds. Rest only after you've completed a full round. No rest between exercises.`,
        workout: [
            'Pushups', 
            'Situps',
            'Squats',
            'Pike Pushups',
            'Leg Raises', 
            'Lunges',
            'Burpess',
            'V-ups',
            'Squat Jumps',
        ]
    },
    {
        id: 3,
        workoutType: 'RestOnInterval',
        workoutName: '90/90',
        totalRounds: 9,
        intervalMinutes: 1,
        intervalSeconds: 30,
        restMinutes: 1,
        restSeconds: 30,
        workoutDescription: `90 seconds of work, 90 seconds of rest. When you get this much rest, your work intervals need to be tough. GO ALL OUT. Mark off a distance of about 100 yards. (You can also do 100 mountain climbers as a running substitute.) Start each 90 second round with a run out and back. When you get back from the run, finish the round with as many reps as possible of the exercise for that round. After 90 seconds, rest 90 and move to the next exercise. Repeat for 9 total rounds.`,
        workout: [
            'Burpees',
            'Squat Jumps',
            'Jumping Lunges'
        ]
    },
    {
        id: 4,
        workoutType: 'RestOnRound',
        workoutName: 'EMOM',
        totalRounds: 4,
        intervalMinutes: 1,
        intervalSeconds: 0,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: 'Every minute on the minute do the reps of the primary exercise, then finish the minute with as many reps as possible of the secondary exercise. After 4 sets rest for a minute',
        workout: [
            '10 Pushups, then Mountain Jacks',
            '10 Lunges ea, then Speed Skaters',
            '10 Situps, then Flutter Kicks',
            '6 Burpees, then Plank'
        ]
    },
    {
        id: 5,
        workoutType: 'RestOnRound',
        workoutName: 'The Demon of Vistamont',
        totalRounds: 6,
        intervalMinutes: 3,
        intervalSeconds: 0,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: '3 min on, 1 min off for 6 rounds, repeat as many times as possible',
        workout: [
            '5 Burpees, 10 V-Ups, 20 Double Unders*'
        ],
        notes: `*If you don't have a Jump Rope, get one. Also, substitute a short run 25m out and back or 20 Mountain Climbers each leg. If you do have a Jump Rope but can't do Doubles yet, do 40 Single Unders`
    },
    {
        id: 6,
        workoutType: 'RunningTimer',
        workoutName: '10 Rounds',
        totalRounds: 1,
        intervalMinutes: 0,
        intervalSeconds: 0,
        restMinutes: 0,
        restSeconds: 0,
        workoutDescription: `Go through each round as fast as possible. No stopping between exercises. No rest until the end. Just hustle.`,
        workout: [
            '1 Tuck Jump Burpee', 
            '5 Mountain Climber Pushups (4 Mtn Climbers + 1 Pushup)',
            '10 V-Ups',
            '15 Star Jumps',
            '20 Speed Skaters', 
            'Rest 30 Seconds',
        ],
        notes: '(Intermediate - start with half the number of reps)',
    },
    {
        id: 7,
        workoutType: 'RestOnInterval',
        workoutName: '3 on, 1 off HIIT Circuits',
        totalRounds: 3,
        intervalMinutes: 3,
        intervalSeconds: 0,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: `Repeat Circuit A for 3 minutes, then rest 1 min. Repeat Circuit B for 3 minutes, then rest 1 min. Repeat, going A-B-A-B-A-B`,
        workout: [
            '5 Pushups, 10 Situps, 15 Squats',
            '5 Pike Pushups, 10 Leg Raises, 10 Lunges each leg',
        ],
        notes: ``
    },
    {
        id: 8,
        workoutType: 'RestOnRound',
        workoutName: 'Power Ladders',
        totalRounds: 2,
        intervalMinutes: 7,
        intervalSeconds: 0,
        restMinutes: 3,
        restSeconds: 0,
        workoutDescription: '2 ladders, 7 min each. Start with 3 reps per exercise, then run 25m out and back, then 6 reps, run, 9 reps, run, etc. Go up by 3 reps after each run. Stick with the same 2 exercise for 7 min, then rest 3 min and move to the next circuit. I made it through the round of 24, how far can you get??',
        workout: [
            'Pushups and Squat Jumps',
            'Situps and Jumping Lunges x2'
        ],
        notes: ''
    },
    {
        id:9,
        workoutType: 'RestOnRound',
        workoutName: 'Combos',
        totalRounds: 5,
        intervalMinutes: 1,
        intervalSeconds: 0,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: `With this workout, we're joining familiar exercises together into one combination movement. Repeat as many times as possible for the minute, then move to the next exercise. Rest 1 min at the end and repeat for 5 rounds.`,
        workout: [
            'Pushup, Shoulder Touch x2, Pushup, Mountain Climber x2',
            'Squat, Lunge x2, Curtsy Lunge x2',
            'Situp with a Twist at the top',
            'Burpees'
        ],
        notes: ''
    },
    {
        id: 10,
        workoutType: 'RunningTimer',
        workoutName: 'Hustle',
        totalRounds: 1,
        intervalMinutes: 0,
        intervalSeconds: 0,
        restMinutes: 0,
        restSeconds: 0,
        workoutDescription: `That's it.  You don't need equipment.  You don't even need a lot of time.  All you gotta do is hustle.
        Start with 16 reps of each followed by a 100 yard run (50 out and back).  Then 15 of each and a run, then 14, 13, 12... all the way down to 1 rep and a run.`,
        workout: [
            'Burpees', 
            'V-ups',
        ],
        notes: '(Advanced, start with 16, Intermediate 13, Beginner 10.)',
    },
    {
        id: 11,
        workoutType: 'RunningTimer',
        workoutName: 'Down and Up',
        totalRounds: 1,
        intervalMinutes: 0,
        intervalSeconds: 0,
        restMinutes: 0,
        restSeconds: 0,
        workoutDescription: `Find a safe spot outside to run about 100 meters (100 long walking strides) out and back (200 yards total) `,
        workout: [
            'Run 200m',
            '40 Pushups',
            '40 Situps',
            '40 Squats',
            'Run 400m',
            '30 Pushups',
            '30 Situps',
            '30 Squats',
            'Run 600m',
            '20 Pushups',
            '20 Situps',
            '20 Squats',
            'Run 800m',
            '10 Pushups',
            '10 Situps',
            '10 Squats',
        ],
        notes: ''
    },
    {
        id:12,
        workoutType: 'RestOnRound',
        workoutName: 'Abs on Abs',
        totalRounds: 3,
        intervalMinutes: 5,
        intervalSeconds: 0,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: `3 Circuits, 5 minutes each, 1 minute rest between`,
        workout: [
            '10 Pushups, 10 Pushup Plank Shoulder Touches, 10 Plank Leg Raises',
            '10 Leg Raises, 10 Single Leg Hip Bridges ea side, 10 Toe Touches* ',
            '10 Situps, 10 Russian Twists ea side, 10 Flutter Kicks',
        ],
        notes: '*legs in the air, reach for your toes'
    },
    {
        id:13,
        workoutType: 'RestOnRound',
        workoutName: 'Core Crusher',
        totalRounds: 3,
        intervalMinutes: 0,
        intervalSeconds: 30,
        restMinutes: 2,
        restSeconds: 0,
        workoutDescription: `30 seconds per exercise. Go straight down the list. At the end, rest 2 Minutes and repeat for 3 rounds.`,
        workout: [
            'Plank',
            'Side Plank Right',
            'Side Plank Left',
            'Pushup Plank Hold',
            'Plank to Pushup',
            'Plank Leg Raises',
            'Single Leg Hip Bridges Right',
            'Single Leg Hip Bridges Left',
            'Hollow Hold',
            'Situps',
            'Pushups',
            'Supermans',
            'Squats',
            'Lunges'
        ],
        notes: ``
    },
    {
        id:14,
        workoutType: 'RestOnRound',
        workoutName: '5 Minute Full Body',
        totalRounds: 5,
        intervalMinutes: 0,
        intervalSeconds: 30,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: `10 exercises, 30 seconds each. Rest 1 minute at the end of the round.  Repeat 5 times`,
        workout: [
            'Pushups',
            'Situps',
            'Squats',
            'Pike Pushups',
            'Leg Raises',
            'Jump Squats',
            'Plant to Pushup',
            'V-Ups',
            'Jumping Lunges',
            'Burpees',
        ],
        notes: ``
    },
    {
        id: 15,
        workoutType: 'RestOnInterval',
        workoutName: '40/20 HIIT',
        totalRounds: 4,
        intervalMinutes: 0,
        intervalSeconds: 40,
        restMinutes: 0,
        restSeconds: 20,
        workoutDescription: '40 seconds of work, 20 seconds of rest. Go straight down the list. 4 Rounds - the only rest you get is the 20 seconds between exercises.  This one is all about hustle. ',
        workout: [
            'Burpees',
            'Plank to Pushup',
            'Bicycle Crunches',
            'Curtsy Lunges (Speed Skaters for advanced)',
            'Mtn Climbers',
            'Jumping Jacks (High knees for advanced)',
        ]
    },
    {
        id:16,
        workoutType: 'RestOnRound',
        workoutName: 'Quarantine Special',
        totalRounds: 4,
        intervalMinutes: 0,
        intervalSeconds: 30,
        restMinutes: 1,
        restSeconds: 30,
        workoutDescription: `30 seconds per exercise, no rest between.  Rest 90 sec after finishing the entire circuit.  Repeat 4x`,
        workout: [
            'Jumping Jacks',
            'High Knees',
            'Pushups',
            'Mountain Climbers',
            'Supermans',
            'Flutter Kicks',
            'Scissor Kicks',
            'Squats',
            'Jumping Lunges',
            'Burpees!',
        ],
        notes: `(Repeat 5x advanced)`
    },
    {
        id:16,
        workoutType: 'RestOnRound',
        workoutName: '7&7',
        totalRounds: 2,
        intervalMinutes: 7,
        intervalSeconds: 0,
        restMinutes: 3,
        restSeconds: 0,
        workoutDescription: `Don't hold back on this one.  Move as quickly as you can!
        7 minutes, AMRAP (as many rounds as possible), Rest 3 min, 7 min AMRAP`,
        workout: [
            '5 Pushups, 10 Situps, 15 Squats',
            '5 Burpees, 10 Mtn Climbers (ea leg), 15 Squat Jumps'
        ],
        notes: ``
    },
    {
        id:17,
        workoutType: 'RestOnRound',
        workoutName: '3 Circuits',
        totalRounds: 6,
        intervalMinutes: 3,
        intervalSeconds: 0,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: `3 minutes each, 1 minute rest after. Do each circuit twice.`,
        workout: [
            '10 Pushups, 10 Situps, 10 Squats',
            '10 Supermans, 10 Leg Raises, 10 Lunges each leg',
            '10 Burpees, 10 V-Ups, 10 Mountain Climbers each leg'
        ],
        notes: ``
    },
    {
        id:18,
        workoutType: 'RestOnRound',
        workoutName: '30 Minutes of Misery',
        totalRounds: 10,
        intervalMinutes: 0,
        intervalSeconds: 30,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: `This one gets tough quick.  
        30 Seconds per exercise, going as fast as you can!`,
        workout: [
            'Burpees',
            'Mountain Climbers',
            'Bicycle Crunches',
            'Squat Jumps',        
        ],
        notes: ``
    },
    {
        id: 19,
        workoutType: 'RestOnRound',
        workoutName: 'EMOM',
        totalRounds: 4,
        intervalMinutes: 1,
        intervalSeconds: 0,
        restMinutes: 1,
        restSeconds: 0,
        workoutDescription: 'Every minute on the minute do the reps of the primary exercise, then finish the minute with as many reps as possible of the secondary exercise. After 4 sets rest for a minute',
        workout: [
            '10 Pushups, then Mountain Climbers',
            '10 Lunges ea, then Jumping Squats',
            '10 V-ups, then Russian Twist',
            '6 Burpees, then Jumping Jacks'
        ]
    },
    {
        id: 20,
        workoutType: 'RestOnRound',
        workoutName: 'Death by Burpees',
        totalRounds: 20,
        intervalMinutes: 1,
        intervalSeconds: 0,
        restMinutes: 0,
        restSeconds: 0,
        workoutDescription: `Don't freak out.  This one’s tough, but you’ll be tougher.  This is an Every Minute On The Minute workout.  Start with 5 Burpees the first minute.  Rest the remainder of the minute.  Then do 6 Burpees the next minute.  Rest with whatever time is left.  Then 7, 8, 9.... Keep going until you can't finish the Burpees in the minute.  When that happens, rest the next full minute, then try to complete the reps you weren't able to finish.  If you complete them, move on.  If not, rest another minute and try again.  How far can you get in 20 minutes??`,
        workout: [
            'Burpees! 5 + Completed Rounds'
        ]
    },
]