import React from 'react';

const Navbar = () => {
    return (
        <nav style={{textAlign: 'center', fontSize: '1.5rem'}}>
            <h1>Some Hard Fucking Workout</h1>
        </nav>
    )
}

export default Navbar;